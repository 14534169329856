import React from 'react';
import { Card, Col, Row } from 'antd';
import { PieChartTwoTone, DollarTwoTone, ToolTwoTone, PrinterTwoTone, ApiTwoTone, ThunderboltTwoTone } from '@ant-design/icons'; // Add the specific icons here
import './App.css';

const App = () => (
  <>
    <h1 style={{ color: "orange" }}>
      <center><img height="100px" src="/assets/img/kash.png" alt="Kashtech Solutions"/></center>
    </h1>
    <br />
    <Row gutter={[16, 16]} justify="center">
      <Col xs={24} sm={12} md={8} lg={4}>
        <a href="https://unitech-frontend.binarywavesolutions.com" target="_blank" rel="noopener noreferrer">
          <Card>
            <center><ToolTwoTone style={{ fontSize: '48px' }} /></center>
            <br />
            <center><h3>Injection</h3></center>
          </Card>
        </a>
      </Col>
      &nbsp;&nbsp;
      <Col xs={24} sm={12} md={8} lg={4}>
        <a href="https://smt-frontend.binarywavesolutions.com" target="_blank" rel="noopener noreferrer">
          <Card>
            <center><PrinterTwoTone style={{ fontSize: '48px' }} /></center>
            <br />
            <center><h3>SMT</h3></center>
          </Card>
        </a>
      </Col>
      &nbsp;&nbsp;
      <Col xs={24} sm={12} md={8} lg={4}>
        <a href="https://harnet-frontend.binarywavesolutions.com" target="_blank" rel="noopener noreferrer">
          <Card>
            <center><ApiTwoTone style={{ fontSize: '48px' }} /></center>
            <br />
            <center><h3>Inventory</h3></center>
          </Card>
        </a>
      </Col>
    </Row>
    <br />
    <Row gutter={[16, 16]} justify="center">
    <Col xs={24} sm={12} md={8} lg={4}>
        <a href="https://pricing-frontend.binarywavesolutions.com" target="_blank" rel="noopener noreferrer">
          <Card>
            <center><DollarTwoTone style={{ fontSize: '48px' }} /></center>
            <br />
            <center><h3>Pricing AI</h3></center>
          </Card>
        </a>
      </Col>
      &nbsp;&nbsp;    
      <Col xs={24} sm={12} md={8} lg={4}>
          <Card>
            <center><PieChartTwoTone style={{ fontSize: '48px' }} /></center>
            <br />
            <center><h3>KASHPeak</h3></center>
          </Card>
      </Col>
      &nbsp;&nbsp;
      <Col xs={24} sm={12} md={8} lg={4}>        
          <Card>
            <center><ThunderboltTwoTone style={{ fontSize: '48px' }} /></center>
            <br />
            <center><h3>Upcoming</h3></center>
          </Card>
      </Col>
    </Row>
  </>
);

export default App;